* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

BODY{
  overflow-x: hidden;

  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

#maincontent{
    @media screen and (min-width: $screen-md-min) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

// Sections
legend{
 font-size: $font-size-base;
}

.text-xs { font-size: $font-size-smaller; }
.text-sm { font-size: $font-size-small; }

.pull-left {
  float: left;
}

