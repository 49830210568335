.c-card {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  color: $gray-dark;
  font-size: 12px;
  display: block;
  padding: 30px 10px;
  text-transform: uppercase;

  &:hover { border-color: $primary; }

  i {
    color: $gray-dark;
    display: block;
    font-size: 30px;
    margin: 0 auto 10px;
  }
  &:hover i { color: $primary}

  a { color: $gray-dark; }
  &:hover a { color: $primary; }
}
