.flexgrid{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

@mixin flex-col($cols) {
  flex: none;
  width: 100%/$cols;
}

.flex-col-1{ @include flex-col(1); }
.flex-col-2{ @include flex-col(2); }
.flex-col-3{ @include flex-col(3); }
.flex-col-4{ @include flex-col(4); }
.flex-col-5{ @include flex-col(5); }
.flex-col-6{ @include flex-col(6); }
.flex-col-7{ @include flex-col(7); }
.flex-col-8{ @include flex-col(8); }
.flex-col-9{ @include flex-col(9); }
.flex-col-10{ @include flex-col(10); }
.flex-col-11{ @include flex-col(11); }
.flex-col-12{ @include flex-col(12); }
