.imagefix{
    font-size: 0;
    line-height: 0;
}

.bg--projectpad {
    background: url(/img/bg_pp.jpg) no-repeat center center;
    background-size: cover;
}

.bg-white { background: $white; }
.bg-primary { background: $primary; }
.bg-secondary { background: $secondary; }
.bg-tertiary { background: $tertiary; }
.bg-gray { background: $lighter-gray; }
.bg-light-gray { background: $light-gray; }

.max-w-sm { max-width: 960px; }
.max-w-lg { max-width: 1120px; }

.img-small-avatar{
    max-width: 60px;
}

.font-bold { font-weight: bold; }

.text-sm { font-size: $font-size-small; }
.text-center { text-align: center; }
.text-left { text-align: left; }

.text-white { color: $white; }
.text-gray-dark { color: $gray-dark; }
.text-medium-dark-gray { color: $medium-dark-gray; }
.text-gray { color: $gray; }

.text-white { color: $white; }
.text-gray-dark { color: $gray-dark; }
.text-medium-dark-gray { color: $medium-dark-gray; }
.text-gray { color: $gray; }

.w-full { width: 100%; }
.w-1\/2 { width: 50%; }
.w-1\/3 { width: 33.333%; }
.w-1\/4 { width: 25%; }
.w-1\/5 { width: 25%; }

$sizes: 0, 5, 10, 20, 30, 40, 60, 80, 100;

@each $size in $sizes {
    .m-#{$size} { margin: $size * 1px; }
    .mt-#{$size} { margin-top: $size * 1px; }
    .mb-#{$size} { margin-bottom: $size * 1px; }
    .ml-#{$size} { margin-left: $size * 1px; }
    .mr-#{$size} { margin-right: $size * 1px; }
    .mx-#{$size} {
        margin-left: $size * 1px;
        margin-right: $size * 1px;
    }
    .-mx-#{$size} {
        margin-left: $size * -1px;
        margin-right: $size * -1px;
    }
    .my-#{$size} {
        margin-top: $size * 1px;
        margin-bottom: $size * 1px;
    }

    .p-#{$size} { padding: $size * 1px; }
    .pt-#{$size} { padding-top: $size * 1px; }
    .pb-#{$size} { padding-bottom: $size * 1px; }
    .pl-#{$size} { padding-left: $size * 1px; }
    .pr-#{$size} { padding-right: $size * 1px; }
    .px-#{$size} {
        padding-left: $size * 1px;
        padding-right: $size * 1px;
    }
    .py-#{$size} {
        padding-top: $size * 1px;
        padding-bottom: $size * 1px;
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.row-eq-height {
    @extend .row;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.height-full {
    height: 100%;
}

.color-highlight {
    color: $primary;
}

.block { display: block; }
.flex { display: flex; }
.flex-nowrap { flex-wrap: nowrap; }
.flex-wrap { flex-wrap: wrap; }
.flex-col { flex-direction: column; }

.justify-end { justify-content: flex-end; }
.justify-start { justify-content: flex-start; }
.justify-between { justify-content: space-between; }
.justify-center { justify-content: center; }

.items-center { align-items: center; }

.flex-auto { flex: 1 1 auto; }
.flex-none { flex: 0 1 auto; }

.flex-column{
    display: flex;
    flex-direction: column;

    &> * {
        flex: 1 1 auto;
    }
}

.list-style-none { list-style-type: none; }

%list-reset,
.list-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%list-flex,
.list-flex {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

.break-word{
    word-break: break-word;
}

.nowrap {
    white-space: nowrap;
}

.border-primary {
    border-color: $primary;
}


@media (min-width: 780px) {
    .md:\text-center { text-align: center; }
    .md:\text-left { text-align: left; }

    .md\:block { display: block; }
    .md\:flex { display: flex; }
    .md\:flex-nowrap { flex-wrap: nowrap; }
    .md\:flex-wrap { flex-wrap: wrap; }
    .md\:flex-col { flex-direction: column; }

    .md\:justify-end { justify-content: flex-end; }
    .md\:justify-between { justify-content: space-between; }
    .md\:flex-col { flex-direction: column; }

    .md\:justify-end { justify-content: flex-end; }
    .md\:justify-start { justify-content: flex-start; }
    .md\:justify-center { justify-content: center; }

    .md\:items-center { align-items: center; }

    .md\:flex-auto { flex: 1 1 auto; }
    .md\:flex-none { flex: 0 1 auto; }

    .md\:flex-column{
        display: flex;
        flex-direction: column;

        &> * {
            flex: 1 1 auto;
        }
    }

    .md\:w-full { width: 100%; }
    .md\:w-1\/2 { width: 50%; }
    .md\:w-1\/3 { width: 33.333%; }
    .md\:w-1\/4 { width: 25%; }
    .md\:w-1\/5 { width: 25%; }

    @each $size in $sizes {
        .md\:m-#{$size} { margin: $size * 1px; }
        .md\:mt-#{$size} { margin-top: $size * 1px; }
        .md\:mb-#{$size} { margin-bottom: $size * 1px; }
        .md\:ml-#{$size} { margin-left: $size * 1px; }
        .md\:mr-#{$size} { margin-right: $size * 1px; }
        .md\:mx-#{$size} {
            margin-left: $size * 1px;
            margin-right: $size * 1px;
        }
        .md\:-mx-#{$size} {
            margin-left: $size * -1px;
            margin-right: $size * -1px;
        }
        .md\:my-#{$size} {
            margin-top: $size * 1px;
            margin-bottom: $size * 1px;
        }
    
        .md\:p-#{$size} { padding: $size * 1px; }
        .md\:pt-#{$size} { padding-top: $size * 1px; }
        .md\:pb-#{$size} { padding-bottom: $size * 1px; }
        .md\:pl-#{$size} { padding-left: $size * 1px; }
        .md\:pr-#{$size} { padding-right: $size * 1px; }
        .md\:px-#{$size} {
            padding-left: $size * 1px;
            padding-right: $size * 1px;
        }
        .md\:py-#{$size} {
            padding-top: $size * 1px;
            padding-bottom: $size * 1px;
        }
    }
}

@media (min-width: 960px) {
    .lg:\text-center { text-align: center; }
    .lg:\text-left { text-align: left; }

    .lg\:block { display: block; }
    .lg\:flex { display: flex; }
    .lg\:flex-nowrap { flex-wrap: nowrap; }
    .lg\:flex-wrap { flex-wrap: wrap; }
    .lg\:flex-col { flex-direction: column; }

    .lg\:justify-end { justify-content: flex-end; }
    .lg\:justify-between { justify-content: space-between; }
    .lg\:justify-start { justify-content: flex-start; }
    .lg\:justify-center { justify-content: center; }

    .lg\:items-center { align-items: center; }

    .lg\:flex-auto { flex: 1 1 auto; }
    .lg\:flex-none { flex: 0 1 auto; }

    .lg\:flex-column{
        display: flex;
        flex-direction: column;

        &> * {
            flex: 1 1 auto;
        }
    }

    .lg\:w-full { width: 100%; }
    .lg\:w-1\/2 { width: 50%; }
    .lg\:w-1\/3 { width: 33.333%; }
    .lg\:w-1\/4 { width: 25%; }
    .lg\:w-1\/5 { width: 25%; }

    @each $size in $sizes {
        .lg\:m-#{$size} { margin: $size * 1px; }
        .lg\:mt-#{$size} { margin-top: $size * 1px; }
        .lg\:mb-#{$size} { margin-bottom: $size * 1px; }
        .lg\:ml-#{$size} { margin-left: $size * 1px; }
        .lg\:mr-#{$size} { margin-right: $size * 1px; }
        .lg\:mx-#{$size} {
            margin-left: $size * 1px;
            margin-right: $size * 1px;
        }
        .lg\:-mx-#{$size} {
            margin-left: $size * -1px;
            margin-right: $size * -1px;
        }
        .lg\:my-#{$size} {
            margin-top: $size * 1px;
            margin-bottom: $size * 1px;
        }
    
        .lg\:p-#{$size} { padding: $size * 1px; }
        .lg\:pt-#{$size} { padding-top: $size * 1px; }
        .lg\:pb-#{$size} { padding-bottom: $size * 1px; }
        .lg\:pl-#{$size} { padding-left: $size * 1px; }
        .lg\:pr-#{$size} { padding-right: $size * 1px; }
        .lg\:px-#{$size} {
            padding-left: $size * 1px;
            padding-right: $size * 1px;
        }
        .lg\:py-#{$size} {
            padding-top: $size * 1px;
            padding-bottom: $size * 1px;
        }
    }
}