$primary: #003845;
$secondary: #003845;
$tertiary: #72d0c2;

@font-face {
  font-family: 'Trim-Regular';
  src: url('/fonts/clients/sdc/Trim-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'Trim-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/clients/sdc/Trim-Regular.otf') format("opentype");
}


$font-family-sans-serif:  'Trim-Regular', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
$font-family-serif: $font-family-sans-serif;

@import '../sass/app';

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4{
  font-family: $font-family-serif;
  font-weight: 300;
}

.pcard__main__title h4 {
  text-transform: none;
}

.bg--projectpad {
  background: #d4dbdc !important;
}

.l-main__sidebar {
  background: $primary;
}

#nav li i { color: white; }