.l-main {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  width: 100vw;

  &__meta {
    flex: 0 1 300px;
    min-width: 300px;
    position: relative;
    z-index: 1;
  }

  &__sidebar {
    background: $black;
    color: $white;
    flex: 0 1 250px;
    min-width: 250px;
    padding: 3rem 2rem;
    position: relative;
    z-index: 1;

    a { color: $white; }
    a:hover { color: $gray; }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    flex-wrap: nowrap;

    &__header {
      background: $white;
      flex: 0 1 auto;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    &__body {
      flex: 1 1 auto;
      margin: 0 auto;
      max-width: 1120px;
      padding: 20px;
      width: 100%;
    }

    &__footer {
      flex: 0 1 auto;
    }

  }
}

.l-footer {
  color: $medium-dark-gray;
  font-size: $font-size-small;
  
  a { color: $medium-dark-gray; }
  a { text-decoration: underline; }
}

.menu-toggler {
  background: $white;
  border: 1px solid $gray-dark;
  display: none;
  padding: 10px;
  position: relative;
  transition: all .25s ease;
  z-index: 101;
  width: 45px;

  span {
    background: $gray-dark;
    display: block;
    margin: 3px;
    height: 3px;
    transition: all .25s ease;
  }

  &.isOpen {
    background: $gray-dark;
    border-color: $white;

    span { background: $white; }

    span:nth-child(1) { transform: rotate(135deg) translate(4.2px, -4.2px) }
    span:nth-child(2) {  transform: translateX(-20px); opacity: 0; }
    span:nth-child(3) { transform: rotate(225deg) translate(4.2px, 4.2px) }
  }
}

@media (max-width: 1100px) {
  .l-main__meta {
    flex: 0 1 250px;
    min-width: 250px;
  }

  .l-main__sidebar {
    flex: 1 1 200px;
    min-width: 200px;
  }

  .img-small-avatar { max-width: 40px; }
}

@media (max-width: 780px) {
  .menu-toggler {
    display: block;
  }

  .l-main__sidebar {
    bottom: 0;
    left: 0;
    padding-top: 80px;
    position: fixed;
    right: 0;
    top: 0;
    transition: all .25s ease-in-out;
    transform: translateX(-100vw);
    overflow-x: auto;
    z-index: 100;

    &.isOpen { transform: translateX(0); }
  }
}